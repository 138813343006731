<template>
  <div class="w-100 h-100">
    <div style="width: 100%; background-color: #035c6e; height: 50px"></div>
    <div class='bg-light shadow-sm d-flex align-items-center justify-content-center' style="width: 100%; height: 50px color:#232323;"><h2>Account Settings</h2></div>
    <div class="d-flex align-items-center justify-content-center p-5 h-100 ">
        <div class="w-25 border-right h-100">
            <div class="bg-light d-flex w-100 border-bottom">
                <span>

                </span>
                <h4>Profile</h4>
            </div>
            <div class="bg-light d-flex w-100 border-bottom">
                <span>

                </span>
                <h4>Settings</h4>
            </div>
            <div class="bg-light d-flex w-100 border-bottom">
                <span>

                </span>
                <h4>Notifications</h4>
            </div>
        </div>
        <div class="w-50"></div>
        <div class="w-25">hi</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountSettings',
};
</script>

<style>
</style>
